@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'react-alice-carousel/lib/alice-carousel.css';

@font-face {
  font-family: Qualy;
  src: url(../../public/fonts/Qualy.ttf);
}

@font-face {
  font-family: Roie;
  src: url(../../public/fonts/Roie.ttf);
}

svg {
  display: inline-block !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Inter, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: white;
  background-color: #0f6347;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  transition: all 0.15s ease-in-out;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: white;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.15));
}

.alice-carousel__prev-btn-item span,
.alice-carousel__next-btn-item span {
  font-size: 30px;
  display: block;
  text-align: center;
  padding-top: 4px;
}

.alice-carousel__wrapper {
  text-align: center;
}

.carousel .thumbs {
  text-align: center;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 1px solid #3d756f !important;
  border-radius: 5px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #000 !important;
}

.carousel.carousel-slider .control-arrow {
  padding: 20px !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.outline {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.outline2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #000;
  text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
    1px 1px 0 white;
}

@supports ((text-stroke: 2px #0f6347) or (WebkitTextStroke: 2px #0f6347)) {
  .outline {
    color: transparent;
    webkittextstroke: 2px #0f6347;
    text-shadow: none;
  }
}

@supports ((text-stroke: 2px white) or (WebkitTextStroke: 2px white)) {
  .outline2 {
    color: transparent;
    webkittextstroke: 2px white;
    text-shadow: none;
  }
}

@-webkit-keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* .chakra-icon.chakra-accordion__icon.css-186l2rg {
  background-image: url('/images/sciencebong.jpg');
} */
